/* General styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --black: #0C0B0B;
  --white: #ffffff;
  --muted: #999999;
  --primary: #0A82DF;
  --secondary: #05E7B9;
}

a {
  text-decoration: none;
  color: var(--primary);
}

h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

p {
  color: var(--muted);
  margin-bottom: 0px;
}

em {
  color: var(--muted);
  margin-bottom: 0px;
}

.eye_button {
  position: relative;
}

.eye_button svg {
  position: absolute;
  bottom: 12px;
  right: 30px;
}

.input-text {
  margin-bottom: 16px;
}

.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
  text-transform: capitalize;
}

.button {
  border: none;
  background: var(--primary);
  color: var(--white);
  padding: 6px 20px;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;

  a {
    color: var(--white);
    text-decoration: none;
  }
}

.cards-four-list,
.cards-three-list {
  display: grid;
  gap: 26px;
  margin-bottom: 20px;
}

.cards-four-list {
  grid-template-columns: repeat(4, 1fr);
}

.select-card,
.select-card-active {
  width: 100%;
  height: auto;
  cursor: pointer;
  padding: 10px;
  padding-bottom: 0px;
  border-radius: 10px;
  background: transparent;
  border: none;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  background-color: var(--white);
}

.select-card {
  .select-type-active {
    display: none;
  }
}

.select-card-active {
  .select-type-active {
    position: absolute;
    display: block;
    top: 16px;
    right: 24px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--black);
    transition: 2s;

    span {
      display: block;
      color: var(--white);
      margin-top: 1px;
    }
  }
}

.select-type {
  position: absolute;
  top: 16px;
  left: 24px;

  .free,
  .premium {
    color: var(--white);
    padding: 2px 14px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
  }

  .premium {
    background-color: #E6A859;
  }

  .free {
    background-color: var(--primary);
  }
}

.breadcrumb {
  position: fixed;
  background-color: var(--white);
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 100px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  z-index: 999999999;

  >div {
    font-weight: 600;
  }

  >div p {
    display: inline;
    margin: 0;
    color: var(--black);
    font-weight: 400;
  }

  button {
    margin: 0;
    padding: 12px 64px;
  }
}




.cards-four-list .select-image,
.cards-three-list .select-image {
  height: 200px !important;
}

.cards-three-list {
  grid-template-columns: repeat(3, 1fr);

  .select-image {
    height: 350px !important;
  }
}

.colour-images {
  .select-image {
    padding: 40px 30px 20px;
    height: 230px !important;
  }
}

.select-image {
  width: 100%;
  max-width: 100%;
  height: 300px;
  border-radius: 10px;
}

@media (max-width:1120px) {
  .cards-four-list {
    grid-template-columns: repeat(3, 1fr);

    .select-image {
      height: 280px !important;
    }
  }

  .cards-three-list .select-image {
    height: 280px !important;
  }

}

@media (max-width:786px) {
  .cards-four-list {
    grid-template-columns: repeat(2, 1fr);

    .select-image {
      height: 250px !important;
    }
  }

  .cards-three-list {
    grid-template-columns: repeat(2, 1fr);

    .select-image {
      height: 250px !important;
    }
  }

  .select-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;

    h3 {
      margin-bottom: 0px;
    }

    span {
      font-size: 22px;
      font-weight: 600;
    }
  }

  .breadcrumb {
    padding: 20px 20px;
  }

  .breadcrumb {
    padding: 20px 20px;
  }
}

@media (max-width:470px) {

  .cards-four-list,
  .cards-three-list {
    gap: 10px;

  }

  .cards-four-list .select-image,
  .cards-three-list .select-image {
    height: 200px !important;
  }

  .select-title {
    font-size: 18px;
    font-weight: 600;
  }
}

.select-title {
  font-size: 24px;
  margin: 16px 0px;
  font-weight: 500;
}

.select-head {

  h3,
  span {
    font-size: 22px;
    max-width: 35ch;
  }
}

.furniture-slide {
  margin-bottom: 30px;

  button:focus-visible {
    outline: none !important;
  }

  .slick-slide {
    margin-right: 20px !important;
    margin-bottom: 20px;
  }

  .slick-track {
    height: 400px;
  }

  .slick-dots {
    bottom: -100px !important;
    display: flex !important;
    justify-content: space-between;
  }

  .slick-dots li {
    width: 80px;
    height: 80px;
    margin: 0px !important;
  }

  .slick-next {
    right: 60px;
    top: 40%;
  }

  .slick-prev {
    left: 20px;
    z-index: 111;
    top: 40%;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 50px;
    color: var(--black);
  }

}
.rdt_TableRow{
#cell-roles-undefined{
  .badge{
    font-size: 16px;
    font-weight: 500 !important;
    padding-left: 0;
  }
}
}
.sc-csuSiG{
  .hbotQl{
    padding-left: 5px;
  }
}
.mt-20{
  margin-top: 20px;
}
.eye-btn{
background: #0a82df;
color: #fff;
border: none;
padding: 9px 15px;
border-radius: 5px;
}

.pdf-text{
  font-size: 12px;
  padding-left: 5px;
  color: #6c6969;
}
.nav {
  .active{
    a{

      color: #0A82DF;
    }
  }
}
.faq-limit{
  font-size: 12px;
}

.sc-hHTYSt{
.ekDecj{
  min-height: 70px;
  padding: 10px 8px;
  height: 100% !important;
}
}
.faq{
  .dSZDRP{
    max-height: 100%;
  }
}


.modal {
  margin-top: 92px !important;
}