// Header
header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
}

.nav-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 60px;
  align-items: center;
  padding: 18px 60px;
  background-color: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);

  .logo {
    width: 160px;
    height: 40px;
  }

  .header-profile-info {
    display: flex;
    gap: 30px;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    svg {
      cursor: pointer;
      font-size: 22px;
    }
  }
}

// Panel


.panel-nav-icons {
  position: fixed;
  left: 0;
  top: 112px;
  z-index: 99999;
  background-color: var(--white);
  padding: 10px;
  width: 16%;
  height: 100vh;
  border-right: 1px solid #eee;
  padding-top: 28px;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.07);
  margin-bottom: 20px;

  .nav,
  .nav-pills {
    display: block;
  }

  .nav-item {
    a {
      padding: 8px;
      border-radius: 6px;
      margin-bottom: 20px;
      color: var(--muted);
      cursor: pointer;
      font-weight: 400;
      font-size: 18px;

      svg {
        margin-right: 10px;
      }
    }

    .nav-link.active {
      background-color: #E6F2FC;

      span,
      svg {
        color: var(--primary);
      }
    }
  }
}


.panel-content {
  width: 1150px;
  padding: 30px 10px 40px 70px;
  padding-bottom: 120px;
  position: absolute;
  left: 14%;
  top: 105px // overflow: scroll;
}


// Dashboard
.dashboard {
  .nav-tabs {
    border: none;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin: 30px 0px;
  }

  .nav-tabs .nav-link {
    border: none;
  }

  .nav-link:focus,
  .nav-link:hover {
    background: var(--primary);
  }

  .nav-tabs .nav-link {
    border-radius: 10px;
    background-color: var(--white);
    color: var(--black);
    padding: 20px 16px;
    width: 260px;
    height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 22px;
    display: flex;
    align-items: center;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: var(--primary);
    color: var(--white);
  }

  .info-tab {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .info {
      h3 {
        max-width: 13ch;
      }

      h4 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 0px;

        span {
          font-size: 16px;
          font-weight: 500;
          color: var(--secondary);
          margin-left: 8px;
        }
      }
    }

    .img {
      background-color: var(--white);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 10px;
      width: 80px;
      height: 80px;
      padding: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// Charts
.bar-chart,
.pie-chart,
.line-chart {
  background-color: var(--white);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 22px;
  border-radius: 10px;
  padding: 20px;
  height: fit-content;

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      font-size: 24px;
      margin-bottom: 0px;
    }

    >div h4 {
      font-size: 16px;
      color: #858585;

    }

    .select>.form-select {
      cursor: pointer;
      background-color: #CEE6F9;
      color: var(--primary);
      border: none;
      font-weight: 400;
    }
  }
}

.line-chart>div h4::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  margin-right: 12px;
  background-color: #84C0EF;
}

.line-chart>div h4:first-child::before {
  background-color: #0A82DF;
}

.recharts-wrapper {
  width: 100% !important;
  height: auto !important;
}

.recharts-surface {
  display: flex;
  align-items: center;
}

.chart-label-texts {
  display: flex;
  align-items: center;
  width: 100%;

  >div {
    width: 100%;
    margin-left: -70px;

    >div {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      gap: 15px;

      span {
        display: block;
        width: 2.5%;
        height: 13px;
        border-radius: 50%;
      }

      h3 {
        margin-bottom: 0px;
        width: 97.5%;
      }
    }
  }
}


// Table
.admin-item-table {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 22px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-radius: 12px;
  margin-top: 30px;
  overflow: hidden;

  tr {
    text-align: left;

    .lang-checkbox {
      padding-left: 30px;
      width: 50px;
    }

    th {
      background-color: #F5F5F5;
    }

    td,
    th {
      padding: 14px;
      color: var(--black);
      font-weight: 600;
    }

  }

  .table-data {
    .info {
      width: 30%;

      >div {
        display: flex;
        gap: 20px;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        >div h3 {
          margin-bottom: 1px;
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        >div p {
          font-weight: 400;
        }
      }
    }

    .design {
      width: 22%;

      p {
        color: #454545;
        font-weight: 400;
      }
    }

    .status {
      width: 180px !important;
    }

    .status span {
      background-color: #E6F2FC;
      width: 90px;
      border-radius: 4px;
      padding: 3px;
      display: block;
      text-align: center;
      font-size: 14px;
    }

    .about p,
    .created p,
    .email p,
    .phone p,
    .username p,
    .time p,
    .password p {
      color: var(--black);
      font-weight: 400;
      max-width: 35ch;
    }

    .design {
      width: 160px;

      svg {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 12px;
      }
    }

    .selections {
      button {
        background: transparent;
        border: 1px solid #999;
        padding: 10px 50px;
        border-radius: 12px;
      }
    }
  }
}

// Customers
.customers {
  .customers-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 45px 0px;
  }

  .customers-detail {
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 22px;

    >div {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      align-items: center;

      button {
        border: none;
        background: transparent;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .info {
      margin-top: 6px;

      h2 {
        font-size: 32px;
        margin-bottom: 0px;
      }

      span {
        color: #05E7B9;
        font-weight: 500;
      }
    }
  }

  .customers-options {
    display: flex;
    justify-content: space-between;

    .tabs-details {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      padding: 0;
      margin: 0;

      li {
        border-radius: 12px;
        border: 1px solid var(--muted);
        padding: 10px 16px;
        list-style: none;

        svg {
          margin-left: 15px;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}

// Reports
.reports {
  .reports-dropdown {
    display: flex;
    gap: 20px;
    margin: 30px 0px;
  }

  .reports-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30p;
  }

  .view-change-btn {
    .icon-btn {
      border: none;
      background: transparent;

      img {
        width: 80px;
        height: 32px;
      }
    }
  }

  .filter-btn {
    display: flex;
    gap: 20px;

    button {
      background: transparent;
      border: 1px solid;
      border-color: var(--muted);
      border-radius: 10px;
      height: 44px;
      padding: 0px 20px;

      svg {
        margin-right: 4px;
      }
    }
  }

  .btn {
    display: flex;
    gap: 20px;

    button {
      background: transparent;
      border: 1px solid;
      border-color: var(--muted);
      border-radius: 10px;
      height: 44px;
      padding: 0px 20px;

      svg {
        margin-right: 4px;
      }
    }
  }
}

.report-charts {
  margin-top: 30px;

  >div {
    margin-bottom: 30px;
  }
}


// Master settings
.master-settings {
  >div .filters-title {
    margin-top: 30px;
  }

  .filters {
    .card {
      width: 700px;
      height: fit-content;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 22px;
      border-radius: 10px;
      margin-top: 45px;
      position: relative;
      border: none;

      .grip {
        position: absolute;
        font-size: 10px;
        color: var(--muted);
      }

      .grip1 {
        left: 50%;
        top: 6px;
      }

      .grip2 {
        left: 6px;
        top: 50%;
      }

      .details {
        padding: 30px;

        .inputs {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;

          .input-text input {
            margin-bottom: 0px;
          }

          .select {
            width: 280px;
            display: flex;
            align-items: center;
            border: 1px solid var(--muted);
            padding: 0px 12px;
            border-radius: 10px;

            .drop-icon {
              font-size: 20px;
              color: var(--black);
              margin: 0px;
            }

            .form-select {
              color: #6c757d;
              font-weight: 400;
              border: none;

            }
          }
        }

        .option-icons {
          border-top: 1px solid #eee;
          padding-top: 20px;
          display: flex;
          gap: 20px;
          justify-content: flex-end;
          margin-top: 10px;

          button {
            border: none;
            background-color: transparent;
          }
        }

        .custom-option-icons {
          border-top: 1px solid #eee;
          padding-top: 20px;
          display: flex;
          gap: 20px;
          justify-content: flex-end;
          align-items: center;
          margin-top: 10px;

          .option-button {
            border: none;
            background-color: transparent;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

// Comman code
.search {
  background-color: transparent;
  width: 350px;
  height: 44px;
  border-radius: 10px;
  padding: 2px 4px;
  border: 1px solid;
  border-color: var(--muted);

  span {
    background: transparent;
    border: none;
    color: var(--muted);
  }

  input {
    background: transparent;
    color: var(--muted);
    border: none;

    &:focus {
      border: none !important;
      box-shadow: none !important;
      background: transparent;
    }
  }
}

.input-text {

  label {
    font-size: 20px;
  }

  input,
  textarea {
    height: 44px;
    border-radius: 10px;
    padding: 6px 12px;
    background: transparent;
    color: var(--muted);
    border: 1px solid var(--muted);
    // margin-bottom: 30px;

    &:focus {
      border: 1px solid var(--muted);
      box-shadow: none !important;
      background: transparent;
    }
  }
}

.select-dropdown {
  height: 44px;
  border-radius: 10px;
  padding: 2px 8px;
  border: 1px solid;
  font-weight: 500;
  border-color: var(--muted);
}

.form-select:focus {
  border-color: var(--muted) !important;
  box-shadow: none !important;
}


.option-icons-user {
  // border-top: 1px solid #eee;
  // padding-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  // margin-top: 10px;

  button {
    border: none;
    background-color: transparent;
  }
}

.option-icons-package {
  // border-top: 1px solid #eee;
  padding-top: 0px;
  display: flex;
  gap: 20px;
  justify-content: flex-center;
  margin-top: 0px;

  button {
    border: none;
    background-color: transparent;
  }
}

// Categories and Products

.categories-info-head,
.products-info-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 45px;
  margin-top: 25px;

  h2 {
    margin-bottom: 0px;

    svg {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  >div {
    display: flex;
    gap: 20px;

    button {
      margin-top: 0px !important;
      display: block !important;
      padding: 0px 45px !important;
      height: 44px;
    }
  }
}


.categories-buttons,
.products-buttons {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  margin-top: 40px;

  button {
    padding: 30px;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 0;
    background-color: var(--white);
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 20px;
  }
}


// Categories

.category-card {
  width: 100%;
  height: auto;
  cursor: pointer;
  padding: 10px;
  padding-bottom: 0px;
  border-radius: 10px;
  background: transparent;
  border: none;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  background-color: var(--white);
}

.categories-items,
.products-items {
  display: grid;
  gap: 26px;
  margin-bottom: 200px;
  grid-template-columns: repeat(3, 1fr);

  .product-card {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.category-image {
  width: 100%;
  max-width: 100%;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
}

.category-title {
  font-size: 24px;
  margin: 16px 0px;
  font-weight: 500;
}

.category-type {
  position: absolute;
  top: 16px;
  left: 24px;

  .free,
  .premium {
    color: var(--white);
    padding: 2px 14px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
  }

  .premium {
    background-color: #E6A859;
  }

  .free {
    background-color: var(--primary);
  }
}

.category-details {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;

  img {
    width: 50%;
    height: 400px;
    border-radius: 10px;
  }

  >div {
    h3 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      color: var(--black);
    }

    button {
      border-radius: 20px;
      background-color: #E6A859;
      padding: 6px 35px;
      margin-top: 16px;
    }
  }
}

.material-field-type {
  margin-top: 30px;
}

.add-new-product {

  .add-new {
    display: flex;
    gap: 30px;
    width: 100%;

    .input-text {
      margin-bottom: 16px;
    }

    .m-b-16 {
      margin-bottom: 16px;
    }

    .error-message {
      width: 100%;
      margin-top: 0.25rem;
      font-size: .875em;
      color: #dc3545;
      text-transform: capitalize;
    }

    .add-new-details {
      width: 50%;
    }

    .add-new-check {
      padding: 0;
      margin-top: 30px;

      .Subscription {
        font-size: 20px;
        margin-bottom: 20px;

      }

      .form-check {
        margin-bottom: 20px;
      }

    }

    .fileForm {
      opacity: 0;
    }

    .add-new-file {
      width: 50%;

      .file-upload {
        width: 100%;
        height: 300px;
        background-color: #E6F2FC;
        padding: 25px 15px;
        color: var(--black);
        border: 1px solid var(--muted);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        >div {

          svg {
            display: block;
            margin: 0 auto 10px;
            margin-top: -75px;
            font-size: 50px;
          }

          span {
            color: var(--primary);
          }
        }
      }

      img {
        width: 100%;
        height: 180px;
        object-fit: contain;
        margin-top: 30px;
        display: none;
      }

      img[src] {
        display: block;
      }

      .file-images {
        margin-top: 0px;
        // display: flex;
        // justify-content: start;
        // gap: 30px;

        >div {
          position: relative;
          // width: 23%;

          button {
            background: transparent;
            border: none;
            position: absolute;
            top: 35px;
            right: 6px;
          }
        }

        >div img {
          width: 120px;
          height: 100px;
        }

        >div video {
          width: 120px;
          height: 100px;
        }
      }
    }
  }

  .add-new-btns {
    margin-top: 30px;

    button {
      padding: 10px 45px;
      margin-top: 0;
    }

    .btn-clear {
      margin-left: 20px;
      background: #E6F4FB;
      color: var(--primary);
    }
  }

  .select-options-section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    .select-options {
      width: 100%;
      margin-bottom: 16px;
      position: relative;

      .head {
        cursor: pointer;

        h4 {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 12px;
        }

        button {
          width: 100%;
          height: 44px;
          border-radius: 10px;
          padding: 6px 12px;
          background: transparent;
          color: var(--muted);
          border: 1px solid var(--muted);
          margin-bottom: 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .options {
        background-color: var(--white);
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #999;
        position: absolute;
        top: 82px;
        left: 0;
        right: 0;
        z-index: 11111;

        >div {
          padding: 10px 0px;

          label {
            font-weight: 500;
            margin-left: 12px;
          }
        }
      }
    }
  }
}

.product-card {
  width: 100%;
  height: auto;
  cursor: pointer;
  padding: 20px;
  padding-bottom: 0px;
  border-radius: 10px;
  background: transparent;
  border: none;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  text-align: left;

  .product-image {
    width: 100%;
    max-width: 100%;
    height: 250px;
    border-radius: 10px;
  }

  .product-title {
    font-size: 16px;
    font-weight: 500;
    margin-top: 4px;
  }

  .product-price {
    font-size: 24px;
    font-weight: 500;
    margin: 15px 0px;
    display: block;
  }
}


.sofa-details-hero {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 90px;

  .sofa-details-slides {
    width: 50%;
    border-radius: 10px;
    margin-bottom: 100px;
    border-radius: 10px;
  }

  .slick-list {
    border-radius: 10px;
  }

  .details-slide-img {
    height: 400px;
    border-radius: 10px;
  }

  .slick-dots {
    bottom: -100px !important;
    display: flex !important;
    justify-content: space-between;
  }

  .slick-dots li {
    width: 80px;
    height: 80px;
    margin: 0px !important;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 10px;
  }

  .slick-dots .slick-active {
    border: 1px solid rgb(119, 119, 119);

  }

  .slick-next {
    right: 20px;
  }

  .slick-prev::before,
  .slick-next::before {
    color: rgb(187, 187, 187) !important;
  }

  .slick-prev {
    left: 20px;
    z-index: 111;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 30px;
  }

  .sofa-details-info {
    width: 50%;

    .sofa-details-info-head {
      border-bottom: 2px solid #eee;
      margin-bottom: 20px;

      h3 {
        font-size: 28px;
        font-weight: 600;
      }
    }

    .sofa-info-list {
      padding: 0px;

      li {
        list-style: none;
        font-weight: 500;
        margin-bottom: 12px;
      }

      >li p {
        display: inline;
        font-weight: 400;

      }
    }

    .sofa-details-infos-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 100px;
      border-top: 2px solid #eee;
      padding-top: 20px;
      margin-bottom: 10px;

      >div h5 {
        font-size: 28px;
        margin: 0px;
        margin-bottom: 10px;
      }

      >div h5 span {
        color: var(--black);
        margin-left: 10px;
        font-weight: 600;
      }
    }
  }
}

.popup-box {
  position: fixed;
  background: #00000016;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11111111111111;
  text-align: left;

  .delete-box {
    position: relative;
    width: 40%;
    margin: 0 auto;
    height: auto;
    margin-top: calc(100vh - 75vh - 20px);
    background: var(--white);
    border-radius: 30px;
    padding: 45px;
    text-align: center;

    svg {
      display: block;
      float: right;
      font-size: 24px;
      cursor: pointer;
    }

    img {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
      margin-top: 60px;
    }

    .buttons {
      margin-top: 30px;

      button {
        background: transparent;
        padding: 8px 36px;
        border-radius: 12px;
        border: none;
        font-weight: 600;
      }

      .delete {
        background: #DF300A;
        color: var(--white);
      }
    }
  }
}

// Designs
.design-image {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  margin-top: calc(100vh - 85vh - 20px);
  background: var(--white);
  border-radius: 6px;
  padding: 0px 45px;
  text-align: center;

  img {
    width: 90%;
    height: 600px;
  }

  svg {
    position: absolute;
    right: 40px;
    top: 40px;
    font-size: 24px;
    cursor: pointer;
  }
}

.designs {
  .designs-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin-bottom: 0px;
    }

    >div {
      display: flex;
      gap: 20px;

      button {
        margin-top: 0px !important;
        display: block !important;
        padding: 0px 45px !important;
      }
    }
  }
}

// Designs Items
.designs-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin-top: 45px;

  .card {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
    overflow: auto;
    border: none;

    img {
      width: 100%;
      height: 230px;
      object-fit: cover;
    }
  }

  .card-info {
    padding: 20px;

    h3 {
      font-size: 18px;
    }

    button {
      font-weight: 600 !important;
    }
  }

  .card-options {
    display: flex;
    gap: 20px;

    >div {
      display: flex;
      align-items: center;
    }

    >div span {
      font-size: 14px;
      color: var(--muted);
    }

    >div img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }
}

// Designs Details
.design-details {

  .details-page-hero {
    display: flex;
    align-items: center;
    gap: 60px;
    margin-bottom: 90px;

    .details-slides {
      width: 50%;
      border-radius: 10px;
      margin-bottom: 100px;
      border-radius: 10px;
    }

    .slick-list {
      border-radius: 10px;
    }

    .details-slide-img {
      height: 400px;
      border-radius: 10px;
      object-fit: cover;
    }

    .slick-dots {
      bottom: -100px !important;
      display: flex !important;
      justify-content: space-between;
    }

    .slick-dots li {
      width: 80px;
      height: 80px;
      margin: 0px !important;
    }

    .slick-next {
      right: 20px;
    }

    .slick-prev {
      left: 20px;
      z-index: 111;
    }

    .slick-prev::before,
    .slick-next::before {
      font-size: 30px;
    }

    .details-info {
      width: 50%;

      h2 {
        max-width: 20ch;
      }

      .details-info-options {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 40px;

        span {
          width: 45%;
        }

        span img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }

  }

  .download-design {
    >div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      padding: 20px 0px;

      h3 {
        margin-bottom: 0px;
        font-size: 22px;
      }

      button {
        margin: 0px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .designs-details-tabs {
    margin: 30px 0px;
    border: none;

    .nav-link {
      padding: 0px !important;
      background-color: transparent !important;
      font-weight: 400;
      border: none !important;
      border-radius: none !important;
      margin-right: 20px;
      color: var(--black) !important;
      padding-bottom: 4px !important;
    }

    .nav-link.active {
      font-weight: 700;
      border-bottom: 2px solid !important;
      border-image-slice: 1 !important;
      border-image-source: linear-gradient(to right, var(--primary), var(--secondary)) !important;
    }
  }

  .product-details-tables-info {
    margin-bottom: 40px;

    h3 {
      font-weight: 600;
    }

    p {
      color: var(--muted);
      display: block;
      margin: 20px 0px;
    }
  }

  .product-details-tables {
    width: 100%;
    max-width: 100%;
    display: flex;
  }

  .product-details-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 60px;
    height: fit-content;
    display: flex;

    tr {
      border-bottom: 1px solid rgb(223, 223, 223);

      th {
        font-weight: 600;
        padding: 20px 0px;
        width: 400px;
      }

      td {
        padding: 20px 0px;
        width: 400px;

      }
    }

  }
}

.design-detail-head,
.add-design-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
  margin-top: 20px;

  h2 {
    margin-bottom: 0px;

    svg {
      margin-right: 6px;
      cursor: pointer;
    }
  }

  >div button {
    padding: 10px 60px;
    margin-left: 20px;
    margin-top: 0px;
  }

  >div .btn-edit {
    background-image: none;
    background-color: var(--primary) !important;
  }

  >div .btn-delete {
    background-image: none;
    background-color: #DF300A !important;
  }
}

// Add designs

.add-designs-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 45px;

  .add-design-form-selects {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    >div {
      width: 48%;
    }

    .select {
      label {
        font-size: 20px;
      }

      select {
        font-weight: 400;
        margin-bottom: 30px;
      }
    }
  }

  .add-design-form-btns {
    button {
      padding: 10px 60px;
      margin-top: 0;
    }

    .btn-clear {
      margin-left: 20px;
      background: linear-gradient(to right, #E6F4FB, #E6FCF8);
      color: var(--primary);
    }
  }

  .fileForm {
    opacity: 0;
  }

  .add-design-file {
    width: 100%;
    height: 300px;
    background-color: #E6F2FC;
    padding: 25px 15px;
    color: var(--black);
    border: 1px solid var(--muted);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    >div {

      svg {
        display: block;
        margin: 0 auto 10px;
        margin-top: -75px;
        font-size: 50px;
      }

      span {
        color: var(--primary);
      }
    }
  }

  .file-images {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    >div {
      position: relative;
      width: 23%;

      button {
        background: transparent;
        border: none;
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }

    >div img {
      width: 100%;
      height: 100px;
      object-fit: cover;
    }
  }

  .downloads-info {
    margin-top: 90px;

    >div {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;

      span {
        font-size: 20px;
        display: block;
        font-weight: 500;
        margin-right: 30px;
        width: 200px;
      }
    }
  }

  input[type="file"] {
    border: none;

    &:focus {
      border: none !important;
      box-shadow: none !important;
      background: transparent;
    }
  }

  .form-control::file-selector-button {
    border-radius: 10px;
    background-image: linear-gradient(to right, var(--primary), var(--secondary), );
    border: none;
    color: var(--white);
    padding: 10px 45px;
  }
}

// ==========================Design End=======
.selections-box {
  position: relative;
  width: 50%;
  margin: 0 auto;
  height: auto;
  margin-top: calc(100vh - 85vh - 20px);
  background: var(--white);
  border-radius: 6px;

  >div {
    width: 100%;
    padding: 0px 30px;
    padding-bottom: 30px;

    table {
      width: 100%;
    }
  }

  svg {
    position: absolute;
    right: 50px;
    top: 15px;
    font-size: 24px;
    cursor: pointer;
  }
}

.purchase .head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px;
  gap: 60px;

  h2 {
    min-width: 240px;
  }

  button {
    background: transparent;
    border: 1px solid;
    border-color: var(--muted);
    border-radius: 10px;
    height: 44px;
    padding: 0px 20px;

    svg {
      margin-right: 6px;
    }
  }

  .m-t-4 {
    margin-top: 24px;
  }

  .datepicker {
    display: flex;

    .start-date {
      position: relative;
      z-index: 999;

      .calender_ico_from {
        position: absolute;
        top: 38px;
        right: 30px;
      }

    }

    .end-date {
      position: relative;
      z-index: 999;

      .calender_ico_to {
        position: absolute;
        top: 38px;
        right: 12px;
      }
    }
  }
}


// Customer-details

.customer-details {
  .head {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;

    h2 {
      margin: 0px;

      span {
        color: var(--primary);
      }
    }
  }

  h3 {
    font-size: 22px;
    margin: 0px;
  }

  table {
    padding: 12px;
    display: inline-block;
    border-radius: 12px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 22px;
    margin-bottom: 14px;
    width: 650px;

    th,
    td {
      padding: 12px;
    }

    .design-summary {
      border-top: 1px solid rgb(226, 226, 226);

      .info {
        padding-top: 20px;
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          width: 100px;
          height: 100px;
          border-radius: 12px;
        }

        p {
          font-weight: 500;
          max-width: 26ch;
          color: var(--black);
          cursor: pointer;
        }
      }

      .view-details p {
        font-weight: 500;
        color: var(--primary);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .details-box {
    padding: 24px;
    border-radius: 12px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 22px;
  }

  .order-details-box {
    width: 650px;
    margin-bottom: 20px;

    .info {
      margin-top: 20px;

      >div {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgb(226, 226, 226);
        padding: 16px 0px;

        p {
          font-weight: 500;
          color: var(--black);
        }

        .view {
          color: var(--primary);
          text-decoration: underline;
          margin-right: 20px;
        }
      }
    }
  }

  .order-summary {
    width: 420px;
    margin-bottom: 20px;

    .head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      span {
        padding: 6px 12px;
        border-radius: 6px;
        background-color: #FAECDA;
        font-weight: 500;
      }
    }

    .info {
      >div {
        display: flex;
        justify-content: space-between;
        padding: 8px 0px;

        :first-child {
          font-weight: 500;
        }

        p {
          color: var(--black);
        }
      }
    }
  }

  .bill-address {
    width: 420px;

    .info {
      >div {

        padding: 8px 0px;

        :first-child {
          font-weight: 500;
        }

        p {
          color: var(--black);
          display: inline;
        }
      }
    }
  }
}



// Users
.main {
  background: #f8f8f8;
  padding: 178px 0;
}

.users {

  .user-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0px;
    }

    .button {
      margin: 0px;
      padding: 12px 36px;
    }
  }

  .add-new-form {
    margin-top: 45px;
    padding: 50px 70px 50px 70px;
    background: rgb(255 255 255 / 90%);
    box-shadow: 0 15px 16.83px 0.17px rgb(0 0 0 / 5%);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 500px;
    }

    .buttons {
      .button {
        padding: 12px 46px;
      }

      .clear-btn {
        margin-left: 12px;
        color: var(--primary);
        background: rgba(10, 130, 223, 0.1);
      }
    }

    .forgot_password {
      color: var(--primary);
      font-weight: 600;
      margin-left: 6px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      gap: 30px;
    }

    .form-title {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 33px;
    }

    .signup-form {
      margin-right: 92px;
    }

    .signup-image {
      margin: 45px 50px 50px;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}


// Filter 
.filter {
  position: fixed;
  background: #00000016;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;

  .filter-info {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 320px;
    background: var(--white);

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      padding-top: 40px;
      border-bottom: 1px solid #eee;

      h2 {
        margin: 0px;
      }

      svg {
        font-size: 22px;
        cursor: pointer;
      }
    }
  }


  .filter-options {
    padding: 20px;
    margin-bottom: 16px;
    border-bottom: 1px solid #eee;
    margin: 0px;

    input[type="radio"] {
      /* remove standard background appearance */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* create custom radiobutton appearance */
      display: inline-block;
      width: 25px;
      height: 25px;
      padding: 6px;
      /* background-color only for content */
      background-clip: content-box;
      border: 2px solid var(--primary);
      background-color: none;
      border-radius: 50%;
    }

    /* appearance for checked radiobutton */
    input[type="radio"]:checked {
      background-color: var(--primary);
    }

    >div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-size: 18px;
        margin-bottom: 0px;
        color: var(--black);
        cursor: pointer;
        font-size: 500;
      }

      button {
        background: transparent;
        border: none;

        svg {
          color: var(--black);
          font-size: 18px;
          float: right;
        }
      }
    }

    .check-options {
      margin-top: 20px;

      >.options {
        width: 100%;

        >div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 20px;

          label {
            font-size: 18px;
          }
        }
      }

      .datepicker {
        display: block !important;

        .start-date,
        .end-date {
          display: flex;
          align-items: center;
          border: 1px solid #999;
          border-radius: 12px;
          padding: 4px 22px;

          label {
            font-size: 16px;
            margin-right: 12px;
            font-weight: 500;
          }

          input {
            width: 100px;
            border: none;
            outline: none;
            font-size: 16px;
            display: block;
          }
        }

        .start-date {
          margin-bottom: 20px;
        }
      }
    }

  }

  .filter-btns {
    padding: 0px 20px;
    margin-top: 120px;

    button {
      padding: 8px 42px;
    }

    .clear-bnt {
      background: rgba(209, 233, 255, 0.884);
      color: var(--primary);
      margin-right: 20px;
    }
  }
}

.add-new-select-options {
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  .head {
    cursor: pointer;

    span {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      padding: 6px 12px;
      background: transparent;
      color: #6c757d;
      border: 1px solid var(--muted);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .options {
    background-color: var(--white);
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #999;
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    z-index: 11111;

    >div {
      padding: 4px 0px;
      display: flex;
      align-items: center;

      input {
        margin: 0;
        height: 30px;
      }

      label {
        font-weight: 500;
        margin-left: 12px;
      }
    }

    .add-new-btns {
      button {
        padding: 6px;
        width: 120px;
        margin-top: 10px;
      }

      .btn-clear {
        background: #ddf0ff;
        color: var(--primary);
      }
    }
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.hide-scrollbar {
  overflow-y: scroll;
  height: calc(80vh - 20px);
}

.custom-border {
  border: 1px solid;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.error-input {
  border: 1px solid #e71010 !important;
}

.error-select {
  border: 1px solid #e71010 !important;
  border-radius: 4px;
}

.form-switch .form-check-input:focus {
  box-shadow: none;
}

.settings {
  input[type="file"] {
    border: none;

    &:focus {
      border: none !important;
      box-shadow: none !important;
      background: transparent;
    }
  }

  .form-control::file-selector-button {
    border-radius: 10px;
    background-image: linear-gradient(to right, var(--primary), var(--secondary), );
    border: none;
    color: var(--white);
    padding: 10px 45px;
  }
}

.settings-btn {
  border: none;
  background: var(--primary);
  color: var(--white);
  padding: 11px 52px;
  border-radius: 10px;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 700;
}

.custom-select-multi {
  height: 44px;
  border-radius: 10px;
  padding: 6px 12px;
  background: transparent;
  color: var(--muted);
  border: 1px solid var(--muted);
  margin-bottom: 30px;
}

.custom-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 0;
}

// Notification

.notification-popup {
  background: var(--white);
  width: 360px;
  height: fit-content;
  position: absolute;
  top: 70px;
  right: 190px;
  z-index: 11111111111111;
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.notification-popup .notification-popup-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30;
  margin-bottom: 15px;

  h5 {
    font-weight: 400;
  }

  >div svg {
    font-size: 22px;
    display: block;
    margin-bottom: 4px;
  }

  >div span {
    color: var(--muted);
  }
}

.notification-popup-content {
  display: flex;
  justify-content: space-between;
  align-items: top;
  gap: 15px;
  border-top: 1px solid #eee;
  text-align: left;
  padding: 15px 0px;

  .popup-image {
    position: relative;

    span {
      display: block;
      width: 7px;
      height: 7px;
      background-color: #DF300A;
      border-radius: 50%;
      position: absolute;
      top: -2px;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 10px;
    }
  }


  .popup-content {
    h4 {
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 6px;
    }

    p {
      color: var(--muted);
      font-size: 14px;
      padding-right: 30px;
      margin: 0px;
    }
  }
}

.notification-card {
  margin: 20px 0px 20px 0px;
}

.notification-view {
  align-items: top;
  gap: 15px;
  border-top: 1px solid #eee;
  text-align: left;
  padding: 15px 0px;
}

.view-notifi {
  color: #000;
  font-size: 16px;
  text-align: center;
}

.notification_read {
  font-size: 14px;
  color: #64686a;
  margin-bottom: 0;
  font-weight: 700;
}

.notification_unread {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
  font-weight: 700;
}

.card-notification-main {
  display: grid;
}

.card-notification-main .card-title {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.card-notification-main .card-desc {
  color: #000;
  font-size: 14px;
}

.notification_clearall {
  display: flex !important;
}

.notification-card .card-body {
  padding: 10px 10px 10px 10px;
}

.logged-profile-res {
  display: flex;
  align-items: center;
  gap: 20px;
}

.notification-card .card-body table {
  width: 100%;
}

.notification-card .card-body table tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-card .card-body table tr td button.btn {
  font-size: 0.8rem;
  padding: 3px;
  margin: 6px 6px 6px 6px;
}

.notification-card .card-body table tr td:nth-child(2) {
  text-align: right;
  justify-content: space-around;
}

@media screen and (max-width: 960px) {
  .logged-profile-res {
    display: none !important;
  }

  .notification-popup {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    min-height: 100vh !important;
    margin-top: 0px !important;
    border-radius: 0px !important;
    padding: 20px !important;
    z-index: 9999;
  }

  .notification-popup .notification-popup-info {
    >div {
      display: inline-grid;

      svg {
        font-size: 22px;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }






}